@use '../../../../../stories/scss/settings/spacing';
@use '../../../../../scss/variable/breakpoint';
@use '../../../../../scss/variable/font';

.AbTestValentinePage {

  &__main_categories {
    :global {
      .section,
      .wrapper {
        padding: 0;
      }
    }
  }

  &__two_column {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: spacing.$s6;

    @media screen and (min-width: breakpoint.$md) {
      grid-template-columns: 1fr 1fr;
      column-gap: spacing.$s6;
    }

    :global {
      .cro-promotion-banner {
        @media screen and (min-width: breakpoint.$md) {
          height: 320px;
        }
      }

      .cro-promotion-image {
        height: 200px;

        @media screen and (min-width: breakpoint.$md) {
          height: 320px;
        }
      }

      .cro-promotion-content {
        width: 318px !important;
      }

      .cro-promotion-button {
        background-color: transparent;
        color: var(--theme-font-color);
        border: 1px solid rgba(23, 31, 15, 0.32);

        span {
          font-weight: font.$weight-400;
        }
      }
    }
  }

  &__heading {
    display: flex;

    span {
      max-width: 250px;

      @media screen and (min-width: breakpoint.$md) {
        max-width: none;
      }
    }

    &_left {
      justify-content: flex-start;
    }

    &_center {
      text-align: center;
      justify-content: center;
    }
  }
}
